@import "../../Global.scss";

#contact-us .sections .intro {
    align-items: flex-end;

    @include mobile {
        overflow-x: hidden;
        align-items: center;
        width: 100%;
        padding: 0;
    }
}

#contact-us .sections .wrapper {
    width: 50%;
    margin-left: 117px;
    min-width: 650px;

    @include mobile {
        width: 100%;
        min-width: 100%;
        margin: 0;
        // padding: 0 12px;
    }
}

.contact-us {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

form {
    margin: 20px 0;
    background-color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    color: black;

    @include mobile {
        width: 100%;
    }

    .btn {
        flex: 0 0 40%;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.1em;
    }
}

.radio-input-wrapper {
    display: flex;
    margin: 15px;

    input {
        margin: 0 5px;
    }

    label {
        font-size: x-small;
    }
}

.errors {
    color: red;
    font-size: 12px;
}

// input:invalid {
//     border-bottom: 1px solid red;
// }

.input-wrapper {
    position: relative;
    margin: 16px;
    width: 200px;
    height: 70px;
    input {
        margin: 3px;
    }
}