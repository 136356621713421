@import "../../Global.scss";

#services {
    .items {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        color: inherit;
        width: 100%;

        .item {
            flex: 0 0 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 10%;
            margin: 10px ;
            min-width: 100px;
            // border: 1px solid $lightColor;
            height: 200px;
            box-sizing: border-box;
            border-radius: 10px;

            &:hover  .item-body .desc {
                opacity: 1;
            }

            .item-top {
                border-bottom: 2px solid;
                text-align: center;
                padding: 10px;
                width: 60%;

                color: $lightSecondColor;

                img {
                    height: auto;
                    width: 70%;
                    object-fit: cover;

                }
            }

            .item-body {
                width: 100%;
                padding: 10px;
                text-align: center;
                box-sizing: border-box;

           

                .desc {
                    opacity: 0;
                    margin-top: 10px;
                    font-size: 12px;
                }
            }
        }
    }
}