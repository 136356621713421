@import "../../Global.scss";

.container {
    display: flex;
    flex-direction:row ;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include mobile{
        flex-direction:column ;
    }

    .box {
       // width: 300px;
        background-color: $lightColor;
        color: $secondColor;
        margin: 12px;
        padding: 20px;
    
        .title {
            color: inherit;
            text-decoration: underline;
            font-size: 24px;
        }
    
    }
}

