@import "../../Global.scss";

.title-container{
    margin-top: 20px;
    width: 100%;
}
.title {
    margin: 0 auto;
    text-align: center;
    color: $secondColor;
    margin-bottom: 20px;
    position: relative;
    font-weight: 600;
    font-size: 34px;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    @include mobile {
        font-size: 28px;
    }
    &.line {
        display: table;
        white-space: nowrap;
        @include mobile {
            white-space: normal;
        }

        &:before, &:after {
          border-top: 1px solid $lightSecondColor;
          content: '';
          display: table-cell;
          position: relative;
          top: 0.5em;
          width: 400px;
          @include mobile {
            width: 70px;
        }
        }
        &:before { right: -5.5%; }
        &:after { left: -5.5%; }
    }

    span {
        display: block;
        font-size: 0.5em;
        line-height: 1.3;
    }
}