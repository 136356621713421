@import "../../Global.scss";

#team {
    background-color: rgb(246, 242, 232);
   

    .items {
        display: flex;
        width: 90%;
        justify-content: center;
        //  align-items: center;
        flex-wrap: wrap;
        color: inherit;
        width: 100%;

        .item {
            flex: 0 0 24%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 18px auto;
            min-width: 150px;
            // border: 1px solid $lightColor;
            // height: 200px;
            box-sizing: border-box;
            border-radius: 10px;

            @include mobile {
                flex: 0 0 40%;
            }

            .item-top {
                border-bottom: 2px solid;
                text-align: center;
                //  padding: 10px;
                //  width: 80%;

                color: $secondColor;

                .img-container {
                    width: 130px;
                    height: 130px;
                    overflow: hidden;
                    margin: 10px;
                    display: inline-block;
                    vertical-align: top;

                    @include mobile {
                        width: 130px;
                        height: 130px;
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                        width: auto;
                        // height: auto;
                        // width: 70%;
                        // object-fit: cover;
                        // margin: 9px;
                    }
                }

            }

            .item-body {
                width: 100%;
                padding: 10px;
                text-align: center;
                box-sizing: border-box;
            }
        }
    }
}