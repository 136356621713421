@import "../../Global.scss";


.features-items {
    overflow: auto;
    padding: 20px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    color: inherit;

    @include mobile {
        justify-content: normal;

        // &::-webkit-scrollbar {
        //     display: none;
        // }
    }

    .item {
        padding: 20px;
        flex: 0 0 150px;
        display: flex;
        flex-direction: column;
        align-items: center;

        // height: 200px;
        box-sizing: border-box;

        &:hover .item-body .desc {
            opacity: 1;
        }

        .item-top {
            border-bottom: 2px solid;
            text-align: center;
            padding: 10px;
            width: 80%;

            color: $lightSecondColor;
        }

        .item-image {

            img {
                height: 60px;
                width: auto;
                object-fit: cover;

            }
        }

        .item-body {
            width: 100%;
            padding: 10px;
            text-align: center;
            box-sizing: border-box;
            font-size: 12px;

            .desc {
                opacity: 0;
                margin-top: 10px;
                font-size: 12px;
            }
        }
    }
}