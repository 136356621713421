@import "../../Global.scss";

.slide-card {
  // min-width: 300px;
  // width: 200px;
  height: 400px;
  position: relative;
  margin: 10px;
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  direction: rtl;
  background-color: $lightColor;

  .top-header {
    font-weight: bold;
    border-bottom: 1px solid;
  }

  .header {
    color: $secondColor;
    padding: 12px;
    font-weight: bolder;
    font-size: 17px;
  }

  .content {
    font-size: 15px;
  }

  .card-img {
    width: 100%;
    object-fit: cover;
    height: 50%;
  }
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 15px;
}

.slick-dots li button:before {
  font-size: 15px !important;
  line-height: 20px !important;
}

.slide-card {
  .slick-prev {
    left: 8px !important;
    z-index: 4;
  }
  .slick-next {
    right: 23px !important;
    z-index: 4;
  }
  .slick-prev:before,
  .slick-next:before {
    content: none !important;
  }
}
