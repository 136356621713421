@import "../../Global.scss";

.projects .wrapper {
    width: 100%;
  //  max-width: 1500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
}

.projects-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 50%;
        aspect-ratio: 3.7 / 2.5;
        overflow: hidden;
        position: relative;
        display: inline-block;
        cursor: pointer;
        border-bottom: 5px solid $mainColor;
        box-sizing: border-box;
       
        @include mobile {
            width: 100%;
        }

        * {
            transition: all 0.4s ease;
        }

        &:hover {
            & .overlay {
                opacity: 1%
            }

            & .image {
                transform: scale(1.2);
            }

        }

        .image {
            background-image: url('../../../public/images/projects/3.jpg');

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            left: 0;

            &:hover {
                transform: scale(1.2);
                -webkit-filter: brightness(70%);
                -moz-filter: brightness(70%);
                -o-filter: brightness(70%);
                -ms-filter: brightness(70%);
                filter: brightness(70%);
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: #000;
            opacity: 29%;
            transition: opacity 0.4s ease;
        }

        .details {

            text-align: center;

           // font-weight: bold;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            padding: 10px;

            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            box-sizing: border-box;

            h1,
            h2 {
                color: $mainColor;
            }

            .project-btn {
                color: $mainColor;
                text-decoration: none;
                background-color: $lightSecondColor;
                padding: 5px 40px;
                font-size: 20px;
                margin-top: 29px;

              
            }
        }
    }


}


