@import "../../Global.scss";

.login-banner {
  width: 100%;
  min-height: 87vh;
  height: auto;
  background-image: url('../../../public/images/home-page-banner.jpg');
  background-position: bottom;
  background-size: cover; //120%;
  margin-bottom: 20px;
   align-items: center;
  margin: 0;
  display: flex;
  justify-content: center;
  @include mobile {
      overflow-x: hidden;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 0 auto;
  padding: 70px 50px 40px 50px;
  width: 50%;

  color: $mainColor;
  background-image: linear-gradient(rgba(0, 0, 0, 0.697), rgba(0, 0, 0, 0.69));
  height: fit-content;

  .title {
    color: inherit;
  }

  @include mobile {
    width: 80%;
    margin: 0;
    font-size: 13px;
    max-height: 100%;
    overflow: auto;
    // padding: 0 12px;
  }
}

input[type=text],
[type=textarea],
[type=email],
[type=phone] {
  width: 200px;
  border: none;
  background: none;
  border-bottom: 1px solid;
  margin: 50px 10px;
  color: inherit;

}

textarea:focus,
input:focus {
  outline: none;
}