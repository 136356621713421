@import "../../Global.scss";

.banner {
  min-height: 87vh;
  height: 87vh;
 
  background-position: center;
  background-size: cover; //120%;
  margin-bottom: 20px;
  pointer-events: none;

  @include mobile {
    height: 50vh;
    min-height: auto;

    iframe {
      display: none;
    }
  }
}

.projects-category-items {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .item {
    flex: 1 0 30%;
    height: 30vw;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;

    @include mobile {
      width: 100%;
      flex: 1 0 100%;
    }

    * {
      transition: all 0.4s ease;
    }

    &:hover {
      & .overlay {
        opacity: 50%;
      }

      & .image {
        transform: scale(1.2);
      }
    }

    .image {
      background-position: inherit;
      background-size: cover;
      background-repeat: no-repeat;

      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      left: 0;

      &:hover {
        transform: scale(1.2);
        -webkit-filter: brightness(70%);
        -moz-filter: brightness(70%);
        -o-filter: brightness(70%);
        -ms-filter: brightness(70%);
        filter: brightness(70%);
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: #000;
      opacity: 29%;
      transition: opacity 0.4s ease;
    }

    .details {
      text-align: center;
      font-weight: bold;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
      box-sizing: border-box;

      h1,
      h2,
      h4 {
        color: $mainColor;
      }

      @include mobile {
        h2 {
          font-size: 16px;
        }

        .desc {
          display: none;
        }
      }

      .project-btn {
        color: $mainColor;
        text-decoration: none;
        background-color: $lightSecondColor;
        padding: 5px 40px;
        font-size: 20px;
        margin-top: 29px;

        @include mobile {
          margin-top: 9px;
          font-size: 16px;
          padding: 5px 10px;
        }
      }
    }
  }
}
