@import "../../Global.scss";

.intro {
  min-height: 90vh;
  padding-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding-bottom: 20px;
  //for background image
  background-image: linear-gradient(rgba(0, 0, 0, 0.467), rgba(215, 200, 200, 0.822)), url('../../../public/images/engineer-meeting-architectural.jpg');

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  line-height: 1.6;

  @include mobile {
    background-size: cover;
  }


  .wrapper {

    padding: 70px 50px 40px 50px;
    width: 70%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.697), rgba(0, 0, 0, 0.69));

    @include mobile {
      width: 80%;
      margin: 0;
      font-size: 13px;
      max-height: 100%;
      overflow: auto;

    }

    .title {
      color: inherit;
    }

    .content{
      width: 70%;
      margin: 0 auto;
      @include mobile {
      width: 100%;
      }

    }
  }
}