@import "../../Global.scss";

.menu {
  width: 300px;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
  z-index: 20;

  &.active {
    right: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 20px;
    font-weight: 300;
    color: white;
    width: 60%;

    li {
      margin-bottom: 25px;

      a {
        font-size: inherit;
        color: $bgColor;
        text-decoration: none;

        &.active{
          border-bottom: 1px solid;
          font-weight: bold;
        }
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
}