@import "./Global.scss";



.app {
    font-family: inherit;
    width: 100vw;


    .sections {
        overflow: hidden;
        position: relative;
        //  margin-top: calc($topNavHeight * 0.5);
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //hide scrollbar for firefox

        @include mobile {
            margin: 0;
            padding: 0;

            // &::-webkit-scrollbar {
            //     //hide scrollbar for others browser(chrom, safari..)
            //     display: none;
            // }
        }



        >* {

            width: 100vw;
            padding-top: 100px;
            scroll-snap-align: start;
        }
    }
}

.page {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    @include mobile {
    }


}

.wrapper {
    width: 60%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;

    @include mobile {
        width: 90%;
        margin: 9px;
        padding: 0;
    }
}

.btn {
    color: $mainColor;
    text-decoration: none;
    background-color: $lightSecondColor;
    padding: 10px 40px;
    border: none;



}

.logo {
    img {
        width: 200px;
        height: auto;
    }
}


.p-20 {
    padding: 20px;
}

.p-50 {
    padding: 50px;
}

.m-20 {
    margin: 20px
}

.m-50 {
    margin: 50px
}

.mt-60 {
    margin-top: 60px;
}

.image-btn {
    border: none;
    background: transparent;

    &:hover {
        opacity: 0.8;
    }
}

.semi-bold{
    font-weight: 600;
}


.text-container {
    p {
        margin-top: 2rem;
    }

    h1 {
        margin-top: 2rem;
        font-size: 3rem;
    }

    h2 {
        margin-top: 2rem;
        font-size: 2rem;
    }
}