@import "../../Global.scss";

footer {
    background-color: $secondColor;
    color: $mainColor;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: auto;
    padding-top: 10px;
}

.contact-row {
    height: 50px;
    width: 100%;
    background-color: $lightColor;
    margin-top: 19px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    >* {}

    p {
        font-size: 14px;
        margin: 0;

        &:not(:last-child)::after {
            content: '  |  ';
        }
    }

    @include mobile {
        display: none;
    }

}

.footer-items {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    padding: 20px;

    .item {
        min-width: 100px;
        padding: 20px;

        h1 {
            border-bottom: 1px $lightColor solid;
            width: fit-content;
            font-size: 12px;
            line-height: 17px;
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                font-size: small;
                margin: 18px 0;
                width: fit-content;
            }
        }
    }
}

a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.link {
   
    &:hover:after {
        transform: scaleX(1);

    }
    &:after {
        display: block;
        content: '';
        border-bottom: solid 1px;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
}