$mainColor: #ffffff;
$secondColor: rgb(0, 0, 0);
$bgColor: rgba(0, 0, 0, 0.732);
$lightColor: rgb(246,	242,	232);
$lightSecondColor: rgb(50, 48, 36);	
$secondText: yellow;
$width: 768px;
$footerHeight: 50px;
$topNavHeight: 71px;

// :root {
//   --circle-size: clamp(1.5rem, 5vw, 3rem);
//   --spacing: clamp(0.25rem, 2vw, 0.5rem);
// }

@mixin mobile {
  @media (max-width: #{$width}) {
    @content
  }
}