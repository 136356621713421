
@import "../../Global.scss";

.card {
    min-width: 200px;
    width: 200px;
    height: 300px;
    flex-wrap: wrap;
    position: relative;
    margin: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    background-color: $lightColor;

    .top-header {
        font-weight:bold;
        border-bottom: 1px solid;
    }

    .header {
        color: $secondColor;
        padding: 12px;
        font-weight: 600;
        font-size: 17px;
    }

    .content {
        font-size: 12px;
    }

    .card-img {
        width: 100%;
        object-fit: cover;
        height: 50%;
    }
}