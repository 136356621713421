@import "../../Global.scss";

.topbar-bg {
    width: 100%;
    height: $topNavHeight;
    background-color: $bgColor;
}

.topbar {
    width: 100%;
    height: $topNavHeight;
    background-color: $bgColor;
    color: $mainColor;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
    transition: all 1s ease;
    box-shadow: 9px 7px 6px 3px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    &.second {
        height: calc($topNavHeight - 20px);
        top: $topNavHeight;
        background-color: rgba($lightColor, .7);
        transition: top 0.3s;
        color: $secondColor;

        @include mobile {
            display: none;
        }
    }

    .wrapper {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: center;


        .left {
            display: flex;
            align-items: center;
            margin-right: 120px;

            .logo {
                font-size: larger;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                display: flex;
                align-items: center;
                position: absolute;
                right: 45px;
                top: 8px;

                span {}

                img {
                    height: 55px;
                    width: auto;

                }
            }

            .itemContainer {
                display: flex;
                align-items: center;
                margin: 0 30px;

                @include mobile {
                    display: none;
                }

                .icon {
                    font-size: 18px;
                    margin-right: 5px;
                    border: 1px solid;
                    border-radius: 50%;
                    padding: 9px;
                }

                .item {
                    color: inherit;
                    text-decoration: none;
                    float: left;
                    display: block;
                    color: inherit;
                    text-align: center;
                    // padding: 10px 10px;
                    transition: all 200ms ease-out;

                    span {
                        font-size: 15px;
                    }

                    &.active {
                        border-bottom: 1px solid;
                        font-weight: bold;
                    }

                    &:hover:after {
                        transform: scaleX(1);
                    }

                    &:after {
                        display: block;
                        content: '';
                        border-bottom: solid 2px;
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                    }
                }

            }
        }

        .right {

            .hamburger {
                width: 32px;
                height: 25px;
                display: none;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                position: fixed;
                left: 19px;
                top: 20px;

                @include mobile {
                    display: flex;
                }

                span {
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }

    &.active {
        background-color: $mainColor;
        color: $bgColor;

        .hamburger {
            span {

                &:first-child {
                    background-color: $bgColor;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    background-color: $bgColor;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}