
* {
  font-family: 'Assistant','Open Sans', sans-serif;
  margin: 0;
}

.hidden{
  display: none;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}