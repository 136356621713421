@import "../../Global.scss";

.slide {
  // min-width: 300px;
  // width: 200px;
  aspect-ratio: 1/1;

  position: relative;
  //  margin: 10px;
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  direction: rtl;

  .top-header {
    font-weight: bold;
    border-bottom: 1px solid;
  }

  .header {
    color: $secondColor;
    padding: 12px;
    font-weight: bolder;
    font-size: 17px;
  }

  .content {
    font-size: 15px;
  }

  .card-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    overflow: hidden;
  }
}
.prev_button {
  left: 8px !important;
  z-index: 4;
  width: auto !important;
  height: auto !important;
}
.next_button {
  right: 23px !important;
  z-index: 4;
  width: auto !important;
  height: auto !important;
}
.prev_button:before,
.next_button:before {
  content: none !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
  line-height: 20px !important;
}

.slick-disabled {
  // display: none !important;
  filter: opacity(50%);
}
