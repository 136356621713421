@import "../../Global.scss";

#contact-us .sections .banner {
    align-items: center;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    @include mobile {
        overflow-x: hidden;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

#contact-us .sections .banner .contact-us {
    width: 50%;
    // margin-left: 117px;
    max-width: 700px;
    margin: 0 0 0 80px;
    @include mobile {
        width: 100%;
        margin: 0;
        // padding: 0 12px;
    }
}

.contact-us {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 70px 50px 40px 50px;
    width: 70%;
    
    color: $mainColor;
    background-image: linear-gradient(rgba(0, 0, 0, 0.697), rgba(0, 0, 0, 0.69));
    height: fit-content;

    &.home{
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(230, 224, 224, 0));
        color: black;
        margin-top: 40px;

        .wrapper {
            // width: 36%;
            box-shadow: 0px 3px 16px #00000029;
        }
    }
    .title {
        color: inherit;
    }

    @include mobile {
        width: 80%;
        margin: 0;
        font-size: 13px;
        max-height: 100%;
        overflow: auto;
        // padding: 0 12px;
    }
}

form {
    margin: 20px 0;
    background-color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    color: black;

    @include mobile {
        width: 100%;
    }

    .btn {
        flex: 0 0 40%;
    }

    
}

.radio-input-wrapper {
    display: flex;
    margin: 15px;

    input {
        margin: 0 5px;
    }

    label {
        font-size: x-small;
    }
}