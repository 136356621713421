@import "../../Global.scss";

.imgs-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    width: 100%;
    height: 100%;

    // @include mobile {
    //     width: 100%;
    //     height: auto;
    // }

    .carousel_slider {
        width: 100%;
        height: 87%;
        overflow: hidden;
        position: relative;

        .image-btn {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }

        .prev_button {
            left: 0;
        }

        .next_button {
            right: 0;
        }

        .content_inner_slider {
            width: 100%;
            height: 100%;
            display: flex;


            .images-title {
                width: 100%;
                position: absolute;
                bottom: 0;
                background: $bgColor;
                color: white;
                height: 31px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
            }

            img {

                //  opacity: 0;
                //  width: 100%;
                //  min-width: 50vw;
                height: 100%;
                object-fit: cover;
                transition: all .3s ease-out;

                //transition: all 1s linear;
                &.hide {
                    display: none;
                    opacity: 0;
                    width: 0;
                    transition: all 3.3s ease-in;
                }

                &.active {
                    width: 100%;
                    opacity: 1;
                    transition: all 3.3s ease-in;
                }
            }
        }
    }



    ul,
    li {
        list-style: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
    }

    .dots {
        display: flex;
        margin: 1rem 0;
        //display: none;

        .dot {
            width: 0.5rem;
            height: 0.5rem;
            border: 1px solid $secondColor;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;

            &.active {
                background-color: $secondColor;
            }
        }
    }


}