@import "../../Global.scss";



.open {
    .toggle-text {
        &::before {
            content: '\0445' !important;
            margin-left: 6px;
            font-size: 20px;
            transition: all 1s ease;
            transform: rotate(90);
        }
    }
}

.topbar-second-mobile {
    display: none;

    @include mobile {
        display: block;

        top: $topNavHeight;
        left: 0;
        z-index: 13;
        background-color: $lightColor;
        transition: top 0.3s;
        color: $secondColor;

        width: 100%;

        position: fixed;
        box-shadow: 9px 7px 6px 3px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        transition: all 1s ease;

        .toggle-text {
            height: calc($topNavHeight - 20px);
            font-weight: 600;

            &::before {
                content: '\002B';
                margin-left: 6px;
                font-size: 20px;
            }
        }

        .items-menu {
            // transform: translateY(100%);
            display: flex;
            height: auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            z-index: 4;
            padding: 0;
            font-size: 16px;
            font-weight: 300;

            height: auto;
            max-height: 0px;
            overflow: hidden;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
            opacity: 0;

            &.open {
                //transform: translateY(0);
                display: flex;
                opacity: 1;
                padding: 20px;
                max-height: 700px;
                transition: max-height 0.76s ease-in-out;
            }

            .itemContainer {
                margin-bottom: 15px;

                .item {
                    font-size: inherit;
                    color: $bgColor;
                    text-decoration: none;

                    &.active {
                        font-weight: bold;
                    }
                }


            }
        }
    }

    .text-btn {
        border: none;
        color: inherit;
        background: inherit;
        font-size: inherit;
        text-align: center;
        width: 100%;
    }
}