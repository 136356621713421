@import "../../Global.scss";

.project-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-features {
  width: 100%;

  .features-items .item .item-image img {
    height: 40px;
  }
}

.boxes {
  display: flex;
  width: 100%;
  justify-content: center;

  @include mobile {
    flex-direction: column;
  }

  .box {
    flex: 0 0 50%;
    background-color: $lightColor;
    margin: 0;
    display: flex;
    min-width: 400px;
    position: relative;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    &.file {
      justify-content: center;
    }

    .img-container {
      width: 100%;
      //height: 500px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.img-background {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.467),
      rgba(74, 68, 68, 0.822)
    ),
    url("../../../public/images/home-page-banner.jpg");
  background-position: bottom;
  background-size: cover;

  width: 100%;
  box-sizing: border-box;
  height: 550px;
  overflow: hidden;
  background: center;
  display: inline-block;
  vertical-align: top;

  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  color: white;

  .title {
    color: inherit;
  }
}

.img-container {
  // width: 250px;
  // height: 250px;
  overflow: hidden;

  display: inline-block;
  vertical-align: top;

  // @include mobile {
  //     width: 130px;
  //     height: 130px;
  // }

  img {
    max-width: 100%;
    height: auto;
    width: auto;
    // height: auto;
    // width: 70%;
    // object-fit: cover;
    // margin: 9px;
  }
}

.sketch-plans {
  .collapse-header {
    color: $mainColor;
    background-color: $lightSecondColor;
    list-style-type: none;
    padding-right: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;

    &:hover {
      opacity: 0.7;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }

    .icon-btn {
      &.active {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }

  ul {
    width: 100%;
    padding: 0;
    list-style-type: none;
    display: none;

    &.open {
      display: block;
    }

    li {
      // padding-right: 10px;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5px;
    }

    .collapse-body {
      list-style-type: none;
      padding-right: 10px;
      height: 50px;

      border: 1px solid $lightSecondColor;
      background-color: $mainColor;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.active {
        background-color: $lightColor;
      }
    }
  }
}

.arrows-btns {
  width: 95%;

  justify-content: space-between;
  display: flex;
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;

  .right {
  }
}

.head-title {
  padding: 12px;
  cursor: pointer;
}

.icon-btn {
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  height: 100%;

  .icon {
    box-sizing: border-box;
    width: auto;
    height: 100%;
    padding: 10px;
    background-color: $lightSecondColor;
  }
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.svg-icon {
  height: 40px;
  background-color: #f2f2f266;
  border-radius: 50%;
}
.boxes {
}
.slider-container {
  min-width: 200px;
  flex: 1;
  // display: "inline-block",
  // margin: "0 auto",
  // height: "fit-content",
  position: relative;
  @include mobile {
    width: 100%;
  }
}
.images-title {
  width: 100%;
  position: absolute;
  bottom: 4px;
  background: $bgColor;
  color: white;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
